/* eslint-disable */
import {autentica} from '../../services/GenericAPI'

const url = '/login'

const state = {
}

const getters = {
}

const actions = {
   async autenticaUsuarios({commit}, {usuario, onSucesso, onErro}) {
      try {
         const res = (await autentica(url+'/auth', usuario))
         sessionStorage.setItem('setor_id', res.data.usuario.setor_id)
         sessionStorage.setItem('acesso', res.data.usuario.acesso)
         onSucesso();
      } catch (error) {
         console.log(error.response);
         this.dispatch('geral/setObjMsgError', error.response.data)
         onErro()
      }
   },
}

const mutations = {

}

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations
}
