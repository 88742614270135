/* eslint-disable */
import {createStore} from 'vuex'
import geral from './modules/geral'
import usuario from './modules/usuarios'
import monitor from "./modules/monitor";
import setores from "./modules/setores";

export default createStore({
  modules: {
    geral,
    usuario,
    monitor,
    setores,
  }
})
