/* eslint-disable */
import {lista} from '../../services/GenericAPI'


const url = '/setor';

const state = {
  allList: [],
};

const getters = {
  listaAllSetor: (state) => {
    return state.allList
  },
};

const actions = {
  async setAllListSetor ({ commit },{onSucesso}) {
    try {
      const list = (await lista(url+'/all')).data;
      commit('SET_ALL_SETORES', list);
      onSucesso()
    }   catch (error) {
      console.log(error);
    }

  }
};

const mutations = {
   SET_ALL_SETORES (state, setor) {
    state.allList = setor
  },


};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
