/* eslint-disable */
import {pegaDados} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {MonitorSCH} from "../../search/MonitorSCH";

const url = '/monitor';

const state = {
  all: [],
  filtro: new Filtro(),
};

const getters = {
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaAbastecimento: (state) => {
    return state.all
  },
};

const actions = {
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', MonitorSCH(perPage))
  },

  async setListAll({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data;
      commit('SET_ALL', list.data.sort(() => Math.random() - 0.5))
      console.log(list.data);
      onSucesso(list)
    } catch (error) {
      onErro()
    }
    },
};

const mutations = {
  SET_ALL(state, all) {
    state.all = all
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
