/* eslint-disable */
import {API} from '../api'

export const pegaDados = (url, data) => {
  return API.post(url, data)
}


export const autentica = (url, objeto) => {
  return API.post(url, objeto)
}

export const lista = (url) => {
  return API.get(url)
}
